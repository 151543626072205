import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { theme } from 'theme';
import { PageContextType } from 'types';

import Layout from 'components/templates/layout';
import BoardSection from 'components/organisms/board-section/board-section';
import HalfBannerDescription, {
  HalfBannerDescriptionType,
} from 'components/organisms/half-banner-description';
import HeadExtendsTeam, { HeadExtendsTeamType } from 'components/organisms/head-extends-team';
import HeadTitleDescriptionImage, {
  BannerPrimaryType,
} from 'components/organisms/head-title-description-image';
import KestriaBanner, { KestriaBannerType } from 'components/organisms/kestria-banner';
import ManagerSection, { ManagerSectionType } from 'components/organisms/manager-section';
import PostsSectionRowSlider, {
  PostsSectionRowSliderType,
} from 'components/organisms/posts-section-row-slider';
import TextBannerCardSection, {
  TextBannerCardType,
} from 'components/organisms/text-banner-card-section';
import BannerCard from 'components/molecules/banner-card';
import LetsTalkBanner, { LetsTalkBannerType } from 'components/molecules/lets-talk-banner';
import { PersonsCardType } from 'components/molecules/person-card';
import Breadcrumb from 'components/atoms/breadcrumb';
import { IconNameType } from 'components/atoms/icon';

import { LinkUrlType } from '../components/atoms/link';

type ExecutiveTeamType = {
  teamList: {
    businessCard: {
      contactList: {
        type: IconNameType;
        link: LinkUrlType;
      };
    } & PersonsCardType;
  }[];
} & Record<'heading' | 'text', string>;

type DataProps = {
  commonComponents: {
    commonComponentsData: {
      letsTalkBanner: LetsTalkBannerType;
    };
  };
  offersExecutiveSearchPage: {
    template: {
      executiveSearchData: {
        bannerQuaternary: BannerPrimaryType;
        recruitmentProcessExecutive: HeadExtendsTeamType;
        expertise: TextBannerCardType;
        executiveKestriaBanner: KestriaBannerType;
        executiveAdvantages: TextBannerCardType;
        guardian: ManagerSectionType;
        executiveTeam: ExecutiveTeamType;
        knowHowArticles: PostsSectionRowSliderType;
        complementaryServices: HalfBannerDescriptionType;
      };
    };
  };
};

const offersExecutiveSearchPage = ({
  pageContext,
  data: {
    offersExecutiveSearchPage: {
      template: {
        executiveSearchData: {
          bannerQuaternary: { heading, image, text },
          recruitmentProcessExecutive,
          expertise,
          executiveKestriaBanner,
          executiveAdvantages,
          guardian,
          executiveTeam,
          complementaryServices,
          knowHowArticles,
        },
      },
    },
    commonComponents: {
      commonComponentsData: { letsTalkBanner },
    },
  },
}: PageProps<DataProps, PageContextType>) => {
  const sectionList = executiveTeam.teamList.map(({ businessCard }) => ({
    position: businessCard.position,
    fullName: businessCard.fullName,
    image: businessCard.image,
    contactList: businessCard.contactList,
  }));

  return (
    <Layout {...{ pageContext }}>
      <HeadTitleDescriptionImage
        showDotsLeft
        titleTop={heading}
        subtitleTop={text}
        image={image}
        positionTitle="left"
        isWhite
      >
        <Breadcrumb {...{ pageContext }} />
      </HeadTitleDescriptionImage>

      <HeadExtendsTeam {...recruitmentProcessExecutive} />
      <TextBannerCardSection
        {...expertise}
        maxWidth={theme.wrapperXs}
        mobilePadding={`${theme.gap.mdl} ${theme.gap.sm}`}
        textColumn
      >
        {expertise
          ? expertise.cardList.map((card, index) => <BannerCard key={index} {...card} />)
          : null}
      </TextBannerCardSection>
      <KestriaBanner {...executiveKestriaBanner} halfBg />
      <TextBannerCardSection
        mobilePadding={`${theme.gap.mdl} ${theme.gap.sm}`}
        {...executiveAdvantages}
        margin="0"
        padding={` 0 0 ${theme.gap.xl}`}
        bgColor={`${theme.colors.grayscaleGray0}`}
      >
        {executiveAdvantages
          ? executiveAdvantages.cardList.map((card, index) => <BannerCard key={index} {...card} />)
          : null}
      </TextBannerCardSection>
      <ManagerSection {...guardian} margin="0" />
      <BoardSection
        mobilePadding={`${theme.gap.mdl} ${theme.gap.sm}`}
        sectionHeading={executiveTeam.heading}
        sectionText={executiveTeam.text}
        sectionList={sectionList}
        bgColor={theme.colors.white}
        iconColor="gray100"
      />
      <PostsSectionRowSlider
        {...knowHowArticles}
        bgColor={theme.colors.grayscaleGray0}
        padding={`${theme.gap.xl} 0`}
        mobilePadding={`${theme.gap.mdl} ${theme.gap.sm}`}
        margin={`${theme.gap.xl} 0 0 0`}
      />
      <LetsTalkBanner {...letsTalkBanner} margin={`0 auto ${theme.gap.xl}`} reverseHalfBg />
      <HalfBannerDescription {...complementaryServices} mobileMargin={`0 0 ${theme.gap.mdl} 0`} />
    </Layout>
  );
};

export const query = graphql`
  query OffersExecutiveSearch($id: String, $commonComponentsSlug: String!) {
    offersExecutiveSearchPage: wpPage(id: { eq: $id }) {
      template {
        templateName
        ... on WpTemplate_OffersExecutiveSearch {
          executiveSearchData {
            bannerQuaternary {
              heading
              text
              image {
                ...WpImageFragment
              }
            }
            recruitmentProcessExecutive {
              heading
              text
              meetTeam {
                cite
                fullName
                position
                linkButton {
                  label
                  icon {
                    name
                  }
                }
                image {
                  ...WpImageFragment
                }
              }
            }
            expertise {
              heading
              text
              cardList: expertiseAdvantageList {
                text
                icon {
                  name
                }
              }
            }
            executiveKestriaBanner {
              kestriaImage: image {
                ...WpImageFragment
              }
              kestriaText: text
              kestriaLinkButton: linkButton {
                icon {
                  name
                }
                label
                link {
                  ariaLabel
                  internal: wpInternal {
                    ... on WpPage {
                      id
                      uri
                    }
                  }
                  external {
                    title
                    target
                    url
                  }
                }
              }
            }
            executiveAdvantages {
              heading
              text
              subTitle
              cardList: advantagesList {
                heading
                text
                icon {
                  name
                }
              }
            }
            guardian {
              backgroundImage {
                ...WpImageFragment
              }
              businessCards {
                businessCard {
                  text
                  fullName
                  position
                  positionTitle
                  text
                  contactList {
                    type
                    link {
                      ariaLabel
                      wpInternal {
                        ... on WpPage {
                          id
                          uri
                        }
                      }
                      external {
                        target
                        url
                      }
                    }
                  }
                  image {
                    ...WpImageFragment
                  }
                }
              }
            }
            executiveTeam {
              heading
              text
              teamList {
                businessCard {
                  fieldGroupName
                  fullName
                  position
                  image {
                    ...WpImageFragment
                  }
                  contactList {
                    type
                    link {
                      ariaLabel
                      wpInternal {
                        ... on WpPage {
                          id
                          uri
                        }
                      }
                      external {
                        target
                        url
                      }
                    }
                  }
                }
              }
            }
            knowHowArticles {
              heading
              subTitle
              text
              posts: choosenPosts {
                ... on WpKnow_how {
                  id
                  title
                  link
                  date(formatString: "DD.MM.YYYY", locale: "PL")
                  categories {
                    nodes {
                      name
                    }
                  }
                  tags {
                    nodes {
                      name
                    }
                  }
                  featuredImage {
                    node {
                      ...WpImageFragment
                    }
                  }
                }
              }
            }
            complementaryServices {
              bannerHeading: heading
              bannerSubHeading: subTitle
              bannerText: text
              bannerLinkButton: linkButton {
                icon {
                  name
                }
                label
                link {
                  ariaLabel
                  internal: wpInternal {
                    ... on WpPage {
                      uri
                    }
                  }
                  external {
                    title
                    url
                  }
                }
              }
              bannerImage: image {
                ...WpImageFragment
              }
            }
          }
        }
      }
    }
    commonComponents: wpPage(slug: { eq: $commonComponentsSlug }) {
      commonComponentsData {
        letsTalkBanner {
          heading
          description
          linkButton {
            label
            icon {
              name
            }
            link {
              ariaLabel
              internal: wpInternal {
                ... on WpPage {
                  uri
                }
              }
              external {
                target
                url
              }
            }
          }
        }
      }
    }
  }
`;

export default offersExecutiveSearchPage;
